import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {ensureArray} from '@matchsource/utils';
import {startWith} from 'rxjs/operators';

export function notEmptyRegexpValidator(regex: RegExp | RegExp[], errorCode: string): ValidatorFn {
  return ({value}: AbstractControl): ValidationErrors | null => {
    const isValid = value === null || value === '' || ensureArray(regex).every(re => re.test(value));

    return !isValid ? {[errorCode]: {value}} : null;
  };
}

export function emptyRegexpValidator(regex: RegExp, errorCode: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = !control.value || regex.test(control.value);

    return !isValid ? {[errorCode]: {value: control.value}} : null;
  };
}

export function toggleDisable(
  control: AbstractControl,
  disabled: boolean,
  opts?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }
): void {
  if (disabled) {
    control.disable(opts);
  } else {
    control.enable(opts);
  }
}

export function setAsTouched(form: UntypedFormGroup | UntypedFormArray) {
  form.markAsTouched();
  form.updateValueAndValidity();
  Object.values(form.controls).forEach(control => {
    if (control instanceof UntypedFormControl) {
      control.markAsTouched();
      control.updateValueAndValidity();
    } else {
      setAsTouched(control as UntypedFormGroup);
    }
  });
}

export function setAsUntouched(form: UntypedFormGroup) {
  form.markAsUntouched();
  form.updateValueAndValidity();
  Object.values(form.controls).forEach(control => {
    if (control instanceof UntypedFormControl) {
      control.markAsUntouched();
      control.updateValueAndValidity();
    } else {
      setAsUntouched(control as UntypedFormGroup);
    }
  });
}

export function getFormEntries(form: UntypedFormGroup): [string, UntypedFormControl][] {
  let result: [string, UntypedFormControl][] = [];
  for (const field in form.controls) {
    if (form.controls[field] instanceof UntypedFormControl) {
      result.push([field, form.controls[field] as UntypedFormControl]);
    } else {
      result = [...result, ...getFormEntries(form.controls[field] as UntypedFormGroup)];
    }
  }
  return result;
}

export const initFormControlValueChanges = (formControl: AbstractControl) =>
  formControl.valueChanges.pipe(startWith(formControl.value));
